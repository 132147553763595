import React, { Component } from 'react';
import PopUp from 'reactjs-popup';
import Popup from "../../components/Popup"
import 'reactjs-popup/dist/index.css';
import Switch from "react-switch";

import styled from "styled-components";
import colors from "../../styles/colors";
import Lottie from 'react-lottie';
import { getString } from '../../utils/translations';
import DatePicker from 'react-date-picker'
import { VIDEO_OPTIONS } from "../../constants"
import {toggleMaskDetection} from "solo-web-engine";

const MAX_VIDEO_SECONDS = 20 * 60; // 20 minutes

class VideoUploadPopup extends Component {

    constructor() {
        super();
        let date = new Date();
        let minutes = date.getMinutes();
        if(minutes < 10) {
            minutes = "0" + minutes;
        }
        let time = `${date.getHours()}:${minutes}`;
       //    console.log("time",time, date)
        this.state = {
            name: null,
            date: date,
            time: time,
            validTime: true,
            option: VIDEO_OPTIONS.multiple,
            videoFile: null,
            nameFieldOpen: false,
            invalidVideoPopupOpen: false,
            videoDuration: null,
            validVideo: false,
            maskModelActive: false
        }

        this.fileInput = React.createRef();
    }

    updateInputValue(evt) {
        const val = evt.target.value;
        this.setState({
            name: val
        });
    }

    loadVideo = file => new Promise((resolve, reject) => {
        try {
            let video = document.createElement('video')
            video.preload = 'metadata'
            video.onloadedmetadata = function () {
                resolve(this)
            }
            video.src = window.URL.createObjectURL(file)
        } catch (e) {
            reject(e)
        }
    })

    handleOptionClick = (option) => {
        this.setState({ option })
    }

    isValidVideoFile = async (videoFile) => {
        const video = await this.loadVideo(videoFile)
        const videoDuration = video.duration


        console.log("videoDuration", {videoDuration, MAX_VIDEO_SECONDS})
        if (videoDuration > MAX_VIDEO_SECONDS) {
            return false
        }

        this.setState({ videoDuration })
        return true
    }

    uploadVideo = async () => {
        const { hideFile } = this.props
        const { name, date, option } = this.state
        if(hideFile){
            this.props.closeHandler({ name, date, option, videoFile: null, videoDuration: this.state.videoDuration })
            return
        }

        const videoFile = document.getElementById('videoUpload').files[0]
        const valid = await this.isValidVideoFile(videoFile)

        if (!valid) {
            this.setState({ invalidVideoPopupOpen: true, validVideo: false  })
        } else {
            this.setState({ videoFile, validVideo: true, name: videoFile.name, nameFieldOpen: true })
           // this.props.closeHandler({ name, date, option, videoFile, videoDuration: this.state.videoDuration })
        }
    }

    submit = () => {
        const { name, date, validVideo, videoFile, option } = this.state
        if(validVideo){
            this.props.closeHandler({ name, date, option, videoFile, videoDuration: this.state.videoDuration })
        }
    }

    closeInvalidVideoPopup = () => {
        this.setState({ invalidVideoPopupOpen: false })
    }

    handleNameClick = () => {
        const { name, nameFieldOpen } = this.state
        if (name && nameFieldOpen) {
            return
        }
        this.setState({ nameFieldOpen: !nameFieldOpen })
    }

    validateTime = () => {
        const { time, date } = this.state

        const timeArr = time.split(':')
        const timeHours = parseInt(timeArr[0])
        const timeMinutes = parseInt(timeArr[1])
        console.log("validateTime", time, timeArr, { timeHours, timeMinutes })
        if (timeHours < 0 || timeHours > 23 || timeMinutes < 0 || timeMinutes > 59 || isNaN(timeHours) || isNaN(timeMinutes)) {
            this.setState({validTime: false})
            return false
        }
        date.setHours(timeHours)
        date.setMinutes(timeMinutes)
        this.setState({validTime: true, date})
        return true
    }

    render() {

        const { isOpen, closeHandler, title, description, loading, loader, error, hideFile } = this.props
        const { name, date, option, nameFieldOpen, invalidVideoPopupOpen, time, validTime , validVideo, maskModelActive} = this.state

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

       // console.log("render filename", name)

        return (
            <div>
                <PopUp
                    position="center"
                    closeOnDocumentClick//={false}
                    open={isOpen}
                    onClose={closeHandler}
                    contentStyle={{
                        width: 335,
                        borderRadius: 9,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: 75
                    }}
                >
                    <PopupTitle>{title}</PopupTitle>

                    {!hideFile ? (
                        <Editable onClick={()=>{this.fileInput.current.click()}} style={{ height: 67, cursor: 'pointer' }}>
                            <input id="videoUpload" ref={this.fileInput} type="file" onChange={this.uploadVideo} style={{ display: 'none' }} />
                            {loading ? (
                                <Lottie
                                    options={defaultOptions}
                                    height={100}
                                    width={100}
                                />) : (
                                getString("choose_file")
                            )}
                        </Editable>
                    ) : null}

                    <Editable style={{ height: 67 }}>
                        {`${getString("choose_a_date")}:`}
                        <DatePicker onChange={(e) => { this.setState({ date: e }) }} value={date} clearIcon={null} />
                    </Editable>

                    <Editable style={{ height: 67 }}>
                        {`${getString("choose_a_time")}:`}
                        <input
                                value={time}
                               onChange={evt => this.setState({ time: evt.target.value })}
                               onClickCapture={e => e.nativeEvent.stopPropagation()}
                               onBlur={this.validateTime}
                               style={{ textAlign: "center",height: 40, width: 170, background: "rgba(0, 0, 0, 0)", marginBottom: 5, border: 'none', display: 'flex' }}
                        />
                        {validTime ? null : (<ErrorMessage>{getString("invalid_time")}</ErrorMessage>)}
                    </Editable>

                    <Editable onClick={this.handleNameClick} style={{ cursor: 'pointer', height: 59 }} >
                        {`${getString("dashboad_name_of_file")}`}
                        {nameFieldOpen &&
                            <input value={name}
                                   onChange={evt => this.updateInputValue(evt)}
                                   onClickCapture={e => e.nativeEvent.stopPropagation()}
                                   style={{ textAlign: "center",height: 40, width: 170, background: "rgba(0, 0, 0, 0)", marginBottom: 5, border: 'none', display: 'flex' }}
                                   autoFocus />}
                    </Editable>

                    <SwitchWrapper>
                        <Switch height={18} width={36} checked={maskModelActive} onChange={(active) => {
                            this.setState({ maskModelActive: active })
                            toggleMaskDetection()
                        }}></Switch>
                        <SwitchLabel>{getString("mask_model")}</SwitchLabel>
                    </SwitchWrapper>


                    <ErrorMessage >{error}</ErrorMessage>

                    <Footer>
                            <FooterButton style={{ background: colors.blue, color: "#fff" }}
                            onClick={this.submit}
                                disabled={!validVideo}
                            >
                                {hideFile ? (<div onClick={this.uploadVideo}>{getString("start")}</div>) : (
                                    <>

                                        {loading ? (
                                            <Lottie
                                                options={defaultOptions}
                                                height={100}
                                                width={100}
                                            />) : (
                                            getString("submit")
                                        )}
                                    </>
                                )}
                            </FooterButton>
                    </Footer>
                </PopUp>


                {invalidVideoPopupOpen && <Popup
                    closeOnDocumentClick={true}
                    isOpen={invalidVideoPopupOpen}
                    closeHandler={this.closeInvalidVideoPopup}
                    title={getString("invalid_video_upload_popup_title")}
                    description={getString("invalid_video_upload_popup_message")}
                    footerButtons={[{ label: getString("okay_button"), onPress: this.closeInvalidVideoPopup }]}
                />}
            </div >
        );
    }
}

export const PopupTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6875em;
  line-height: 2.3125em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0190110625em;
  text-transform: capitalize; 
  /* Main 1 */
  color: #536EEC;
`

export const Editable = styled.div`
    margin-bottom: 25px;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 304px;
    height: 52px;
    background: rgba(224, 231, 255, 0.2);
    border: 0.948611px solid #536EEC;
    box-sizing: border-box;
    border-radius: 4.74306px;
`

const Error = styled.div`
color: red;
`

const Options = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 22px;
`

const Option = styled.div`
    margin: 7px;
    width: 147px;
    height: 55px;
    background: #FFFFFF;
    border: 1.5px solid #536EEC;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    letter-spacing: 0.536252px;
    color: #000000;
    cursor: pointer;
`

const PopupMessage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3125em;
  line-height: 1.4em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #303030;
//   margin-bottom: 0.7em;
`

export const Footer = styled.div`
    display: flex;
    position: absolute;
    bottom: -0.125em;
    left: -0.0625em;
    right: -0.0625em;
    height: 3.125em;
    box-shadow: 0em 0.070974375em 0.88718125em rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 9px 9px;
`

export const FooterButton = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 2.6em;


    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.19643125em;
    line-height: 1.625em;
    text-align: center;
    letter-spacing: 0.03351575em;

    text-transform: capitalize; 
    border-radius: 0px 0px 9px 9px;

    flex: 1
`

const ErrorMessage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375em;
  line-height: 1.35em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing:0.0190110625em;

  /* Main 2 */

  color: #CC1D6C;
`


const SwitchWrapper = styled.div`
    background: #fff;
    border-radius: 10px;
    padding: 3px;
    display: flex;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;

`

const SwitchLabel = styled.span`
    margin-left: 5px;
`


export default VideoUploadPopup;

import firebase from '../api/firebase'
function getCookieValue(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
}

// Check for auth token cookie and sign in with Firebase if found
export async function signInWithAuthToken() {
    const authToken = getCookieValue('solo-auth-token');
    console.log("signInWithAuthToken");
    if (authToken) {
        // Use the token to sign in to Firebase Auth
        try {
            const userCredential = await firebase.auth().signInWithCustomToken(authToken)
            return userCredential.user
        } catch(error){
            console.error("Error signing in with custom token:", error);
            return false
        }
    } else {
        console.error("No auth token found in cookies.");
        return false
    }
}

// Redirect to another subdomain and store Firebase Auth token in a shared domain cookie
export function redirectWithAuthToken(targetSubdomain) {
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        // Set the Firebase Auth token in a cookie with a shared domain
        document.cookie = `solo-auth-token=${idToken};domain=.imsolo.ai;path=/;secure;SameSite=None`;

        // Redirect to the target subdomain
        window.location.href = `https://${targetSubdomain}.imsolo.ai`;
    }).catch(function(error) {
        console.error("Error retrieving Firebase Auth token:", error);
    });
}

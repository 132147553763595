export const TIME_FILTERS = {
    TODAY: "Today",
    WEEK: "week",
    MONTH: "month",
    ALL_TIME: "all_time",
    CUSTOM: "Custom",

}

export const TAG_CONTEXT = {
    RESIDENT: 'Resident',
    SESSION: 'Session',
    VIDEO: 'Video',
    USER: 'User'
}

export const SCREENS = {
    USERS: "users",
    SESSIONS: "sessions",
    CONTENT: "content",
    VIDEO: "video",
    BILLING: "billing",
    GROUP_MGMT: "group_mgmt",
}

export const OVER_TIME_FILTERS = {
    OVER_TIME: "chart_over_time_filter",
    OVER_DAY: "chart_day_filter",
    OVER_WEEK: "chart_week_filter",
    OVER_MONTH: "chart_month_filter"
}

export const OVER_TIME_MODULES_BUTTONS = {
    ENGAGEMENT: "engagement",
    INTEREST: "interest",
    STRESS: "stress",
    MOOD: "mood",
    ENERGY: "energy",
    WELLBEING: "wellbeing",
}

export const OVER_TIME_BUTTONS = {
    ENGAGEMENT: "engagement",
    INTEREST: "interest",
    STRESS: "stress",
    MOOD: "mood",
    ENERGY: "energy",
    WELLBEING: "wellbeing",
    YAWN: "yawn",
    YAWN_RATIO: "yawnRatio",
    EYES: "openEyes",
    LEFT_EYE: "openLeftEye",
    RIGHT_EYE: "openRightEye",
    YAW_ANGLE: "yawAngle",
    PITCH_ANGLE: "pitchAngle",
    FROWN: "eyebrowEyeRatio",
    FROWN_SCORE: "frownScore",
    FROWN_NOSE: "noseEyebrowRatio",
    FATIGUE: "fatigue",
    ATTENTION: "attention",
    BLINKS_PER_MINUTE: "blinksPM",
}
export const OVER_TIME_FACIAL_MUSCLES_BUTTONS = {
//    jawOpen: "jawOpen",
    YAWN_RATIO: "yawnRatio",
    EYES: "openEyes",
  //  mouthOpen: "mouthOpen",
    mouthPucker: "mouthPucker",
    eyesSquint: "eyesSquint",
   /* eyesLookDown: "eyesLookDown",
    eyesLookRight: "eyesLookRight",
    eyesLookLeft: "eyesLookLeft",
    eyesLookUp: "eyesLookUp",*/
    eyesLookHorizontal: "eyesLookHorizontal",
    eyesLookVertical: "eyesLookVertical",

    BLINKS_PER_MINUTE: "blinksPM",

}

export const OVER_TIME_FACE_DIRECTIONS = {
    yawAngle: "yawAngle",
    pitchAngle: "pitchAngle",
}

export const OVER_TIME_EMOTIONS_BUTTONS = {
    HAPPY: "happy",
    SAD: "sad",
    FEARFUL: "fearful",
    ANGRY: "angry",
    SURPRISED: "surprised",
    DISGUSTED: "disgusted",
    NEUTRAL: "neutral",
}

export const TABLE_COLUMNS = {
    ENGAGEMENT: "engagement",
    INTEREST: "interest",
    MOOD: "mood",
    WELLBEING: "wellbeing",
    STRESS: "stress"
}

export const ASYNC_STORAGE_KEYS = {
    TRANSLATIONS: "translations",
    TRANSLATIONS_VERSION: "translations_version",
    TRANSLATIONS_SELECTED_LOCALE: "translations_locale",
    SELECTED_INSUFFICIENT_MODE: "selected_insufficient_mode"
}

export const CLIENT_APP_TYPES = {
    WEB: "Web",
    IOS: "iOS",
    ANDROID: "Android",
    DOCKER: "Docker",
}

export const RESIDENT_TYPE = {
    MULTI: "multi",
    GROUP: "group",
    CARE: "care"
}

export const REQUEST_DATE_FORMAT = "YYYY-MM-DD HH:mm:SS";

export const EMOTIONS = {
    HAPPINESS: "HAPPINESS",
    EMOTIONAL: "EMOTIONAL",
    NEUTRAL: "NEUTRAL",
}

export const LEVELS = {
    HIGH_STRESS: "high",
    NEUTRAL: "neutral",
    MEDIUM: "medium",
    LOW_STRESS: "low",
    HIGH_ENGAGEMENT: 'high_engagement',
    LOW_ENGAGEMENT: 'low_engagement',
    LIKE: "like",
    DISLIKE: "dislike",
    NO: "no_stress_data",
    HIGH: "high",
    LOW: "low"
}

export const TAG_ACTIONS = {
    ADD: "create-option",
    REMOVE: "remove-value"
}

export const HOUSE_TYPES = {
    business: "business",
    private: "private",
    upload: "upload",
    realtime_video: "realtime_video"
}

export const COLUMN_VALUE_OPTION = {
    EMOTION: "emotion",
    METER: "meter"
}

export const STATUS = {
    READY: "ready",
    PENDING: "pending",
    PROCESSING: "processing"
}

export const VIDEO_OPTIONS = {
    multiple: "multiple", //SSD
    single: "single" //TINY
}

export const TAG_FILTERING_MODES = {
    AND: "and",
    OR: "or"
}

export const ANNOTATION_TYPE = {
    HOUSE: "house",
    RESIDENT: "resident",
    SESSION: "session",
    VIDEO: "video",
}

export const BILLING_UNIT_TYPES = {
    DEVICE: "DEVICE",
    AI_UNIT: "AI_UNIT",
    USER: "USER",
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Popup from "../../components/Popup";

import styled from "styled-components";
import loader from '../../lotties/loaderhouse.json'

import {houseLogin, orgLogin, generateAPIKey} from "../../api";
import {getString} from "../../utils/translations";
import {CLIENT_APP_TYPES, SCREENS} from "../../constants"
import _, {get} from "lodash";

import features from '../../features';

import ClientAppWizard from "../../components/ClientAppsWizard";

const APP_PROPS = {
    title: "my_apps",
    description: "my_apps_popup_description"
}

const HOUSE_PROPS = {
    title: "change_house",
    description: "change_house_popup_title"
}

const CREATE_SDK_PROPS = {
    title: "create_sdk_app",
    description: "create_sdk_app_description"
}

const API_KEY_PROPS = {
    title: "api_key",
    description: "api_key_description"
}

class SettingsPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: "",
            popupTitle: props.needToSelectHouse ? getString("select_house") : getString("settings"),
            popupDescription: getString(""),
            content: this.settingsOptions(props),
            reload: null,
            appsWizardActive: false
        }

        this.CLIENT_APPS = [
            {id: 0, title: CLIENT_APP_TYPES.WEB, onClick: this.onAppTypeClicked, current: "web"},
            {id: 1, title: CLIENT_APP_TYPES.IOS, onClick: this.onAppTypeClicked, current: "ios"},
            {id: 2, title: CLIENT_APP_TYPES.ANDROID, onClick: this.onAppTypeClicked, current: "android"}
        ]
    }

    resetState = () => {
        this.setState({
            loading: false,
            error: "",
            popupTitle: this.props.needToSelectHouse ? getString("select_house") : getString("settings"),
            popupDescription: getString(""),
            content: this.settingsOptions(this.props),
            reload: null,
            appsWizardActive: false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentScreen !== this.props.currentScreen) {
            this.setState({content: this.settingsOptions(this.props)})
        }
    }

    onAppTypeClicked = async (clientAppType) => {
        if (this.state.loading) {
            return
        }
        try {
            this.setState({loading: true, error: ""})

            let res = await generateAPIKey(clientAppType)
            this.setState({loading: false, error: ""})

            if (res && res.data) {
                this.setState({reload: true})
                if (res.data.appId) {
                    const content = [{id: 0, title: "app_id", subContent: res.data.appId},
                        {id: 1, title: "api_key", subContent: res.data.apiKey}]
                    this.setState({
                        content,
                        popupTitle: getString(API_KEY_PROPS.title),
                        popupDescription: getString(API_KEY_PROPS.description)
                    })
                } else {
                    const {content} = this.state
                    let updatedContent = _.cloneDeep(content)
                    let index = updatedContent.findIndex((obj => obj.title === "generate_api_key"));
                    updatedContent[index].subContent = res.data.apiKey
                    this.setState({content: updatedContent})
                }
            }
        } catch (e) {
            console.error(e)
            this.setState({loading: false, error: "failed to generate api key for app"})
        }
    }


    settingsOptions = (props) => {
        const {
            onLogout,
            translate,
            needToSelectHouse,
            currentOrganization,
            houseChangeEnabled,
            changeScreen,
            currentScreen
        } = props
        let options = [{id: 1, onClick: translate, title: "change_language"},]

        if (houseChangeEnabled) {
            options.push({
                id: 2,
                onClick: this.changeHousePopup,
                title: HOUSE_PROPS.title
            })
        }


        if (features.billingScreen) {
            let option = {
                id: 4,
                onClick: () => {
                    changeScreen(SCREENS.BILLING)
                    this.props.closeHandler()
                },
                title: getString("billing_screen")
            }
            if (currentScreen === SCREENS.BILLING) {
                option.title = getString("back_to_dashboard")
                option.onClick = () => {
                    changeScreen(SCREENS.USERS)
                    this.props.closeHandler()
                }
            }
            options.push(option)
        }


        if(features.groupManagement && currentScreen !== SCREENS.GROUP_MGMT){
            options.push({
                id: 5,
                onClick: () => {
                    changeScreen(SCREENS.GROUP_MGMT)
                    this.props.closeHandler()
                },
                title: getString("group_management")
            })
        }

        //  if (!needToSelectHouse) {
        options.push({id: 3, onClick: onLogout, title: "log_out"})
        //  }

        if (features.generateApiKey && currentOrganization && !needToSelectHouse) {
            options.unshift({id: 0, onClick: this.appsPopup, title: APP_PROPS.title})
        }

        return options
    }

    appsPopup = () => { //ONLY FOR ORG?!
        let content = []

        let clientApps = this.getClientApps()
        clientApps = clientApps.map(c => {
            let title = this.appTitle(c.appClient);
            if (!title) {
                return null
            }
            return (content.push({id: c.id, onClick: this.onAppClicked, title: title, current: c}))
        })

        //filter null frrom clientApps
        clientApps = clientApps.filter(c => c !== null)

        // console.log("clientApps", clientApps)

        if (clientApps.length < this.CLIENT_APPS.length) {
            content.push({id: 'create', onClick: this.onCreateApp, title: "create_app"})
        }

        this.setState({
            content,
            popupTitle: getString(APP_PROPS.title),
            popupDescription: getString(APP_PROPS.description),
            appsWizardActive: true
        })
    }

    onAppClicked = (clientApp) => {
        const content = [{id: 0, title: "app_id", subContent: clientApp.appId},
            {id: 1, title: "generate_api_key", onClick: this.onAppTypeClicked, current: clientApp.appClient}]

        this.setState({
            content,
            popupTitle: getString(API_KEY_PROPS.title),
            popupDescription: getString(API_KEY_PROPS.description)
        })
    }

    onCreateApp = () => {
        this.setState({
            content: this.getAvailableClientApps(),
            popupTitle: getString(CREATE_SDK_PROPS.title),
            popupDescription: getString(CREATE_SDK_PROPS.description)
        })
    }

    getAvailableClientApps = () => {
        const clientApps = this.getClientApps()
        const availableApps = []
        for (let c of this.CLIENT_APPS) {
            availableApps.push(c)
        }
        return availableApps
    }

    getClientApps = () => {
        const {currentOrganization} = this.props
        const clientApps = []
        if (get(currentOrganization, "OrgClientApps", null) && currentOrganization.OrgClientApps.length) {
            currentOrganization.OrgClientApps.map(clientApp => {
                clientApps.push(clientApp)
            })
        }

        return clientApps
    }

    onSubmit = async (house) => {
        if (this.state.loading) {
            return
        }
        try {
            this.setState({loading: true, error: ""})

            if (house.type === "org") {
                let res = await orgLogin(house.id)
                if (res && res.success) {
                    window.location.reload();
                    return;
                }
            } else {
                let res
                if (house.key) {
                    res = await houseLogin(house.key) //solo
                } else {
                    res = await houseLogin(house.id) //sdk
                }
                if (res && res.success) {
                    window.location.reload();
                    return;
                }
            }
            this.setState({loading: false, error: "wrong house code, try again"})
        } catch (e) {
            console.error(e)
            this.setState({loading: false, error: "wrong house code, try again"})
        }
    }

    appTitle = (app) => {
        switch (app) {
            case "web":
                return CLIENT_APP_TYPES.WEB;
            case "ios":
                return CLIENT_APP_TYPES.IOS;
            case "android":
                return CLIENT_APP_TYPES.ANDROID;
            default:
                return null
        }
    }

    onCancel = () => {
        if (this.state.loading) {
            return
        }
        const {reload} = this.state

        this.setState({
            error: "",
            popupTitle: getString("settings"),
            popupDescription: getString(""),
            content: this.settingsOptions(this.props),
        })

        this.props.closeHandler()
        reload && window.location.reload();
    }

    changeHousePopup = () => {
        const {houses} = this.props
        let content = []
        houses.map(h => {
            if(!h || !h.name){
                return null
            }
            let name = h.name;
            if (h.type === "org") {
                name = `${name} (Org)`
            }
            return (content.push({id: h.id, onClick: this.onSubmit, title: name, current: h}))
        })
        this.setState({
            content,
            popupTitle: getString(HOUSE_PROPS.title),
            popupDescription: getString(HOUSE_PROPS.description)
        })
    }

    render() {
        const {isOpen, closeHandler, needToSelectHouse} = this.props;
        const {loading, error, popupTitle, popupDescription, content, appsWizardActive} = this.state
        if(appsWizardActive){
            return <ClientAppWizard isOpen={isOpen} closeHandler={()=>{
                closeHandler()
                this.resetState()
            }}/>
        }
        return (
            <Popup
                isOpen={isOpen}
                closeHandler={closeHandler}
                title={getString(popupTitle)}
                closeOnDocumentClick={false}
                description={getString(popupDescription)}
                content={content}
                error={error}
                footerButtons={needToSelectHouse ? [] : [{label: getString("close"), onPress: this.onCancel}]}
                loading={loading}
                loader={loader}
                showAll={popupTitle === getString("settings")}
            />
        );
    }
}

SettingsPopup.propTypes = {
    isOpen: PropTypes.bool,
    closeHandler: PropTypes.func,
    user: PropTypes.object,
    houses: PropTypes.array,
    currentHouse: PropTypes.object
};

export default SettingsPopup;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class VideoStream extends Component {
    constructor(props) {
        super(props);
        this.stream = null
        this.mediaRecorder = null
        this.state = {
            recording: false,
            recordedChunks: []
        }
    }

    componentDidMount() {
        this.streamVideo()
    }

    componentWillUnmount = () => {
        this.stopRecording()
        this.stream && this.stream.getTracks().forEach(function (track) {
            track.stop();
        });
        // this.props.streamStopped()
    }

    streamVideo = () => {
        const { width, height } = this.props
        const constraints = {
            audio: false, video: {
                width: { min: 640, ideal: 1920 },
                height: { min: 480, ideal: 1080 },
                frameRate: { min: 30 },
                advanced: [
                    { width: 1920, height: 1080 },
                    { aspectRatio: 4 / 3 },
                    { frameRate: { min: 50 } },
                    { frameRate: { min: 40 } }
                ]
            }
        };
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((mediaStream) => {
                const video = document.querySelector("#videoElement");
                this.stream = mediaStream;

                let videoTracks = mediaStream.getVideoTracks();
                console.log("got media stream", mediaStream, videoTracks[0].getCapabilities(), videoTracks[0].getSettings(), videoTracks[0].getConstraints());
                video.srcObject = mediaStream;
                video.onloadedmetadata = (e) => {
                    video.play();
                    if(this.props.shouldRecord){
                        this.startRecording(mediaStream)
                    }
                    this.onStreamSuccess(video)
                };
            })
            .catch((err) => {
                console.log(err);
            });
    }

    startRecording = async (stream) => {

        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = this.handleDataAvailable;
        this.mediaRecorder.start();
        console.log("recording started", this.mediaRecorder.state, this.mediaRecorder.stream.active)

        this.setState({ recording: true });
    };

    stopRecording = () => {
        this.mediaRecorder.stop();
        this.setState({ recording: false });
    };

    handleDataAvailable = (e) => {
        const {recordedChunks} = this.state
        const {onRecordUpdate} = this.props
        console.log("data available", e.data.size)
        if (e.data.size > 0) {
            console.log("data available", e.data)
            recordedChunks.concat(e.data)
            onRecordUpdate(e.data)
            this.setState({recordedChunks});
        }
    };

    onStreamSuccess = async (input) => {
        const { streamSuccessHandler, updateInput } = this.props
        await updateInput(input)
        streamSuccessHandler && streamSuccessHandler()
    }


    render() {
        const { maxHeight } = this.props
        return (
            <video
                style={{maxWidth: '100%', maxHeight: maxHeight}}
                autoPlay={true}
                id="videoElement"
                ref={this.props.innerRef}
            />
        );
    }
}

VideoStream.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    streamSuccessHandler: PropTypes.func,
    onRecordUpdate: PropTypes.func,
    shouldRecord: PropTypes.bool,
};

VideoStream.defaultProps = {
    width: 1280,
    height: 720
};


export default VideoStream;

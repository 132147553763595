import React, { useState, useEffect, useRef } from 'react';
import {OverTimeButton} from "../../StyledComponents";
import colors from "../../../styles/colors";

const IdsButtons = ({ ids, selectedIds, selectId }) => {
    const scrollContainerRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const checkOverflow = () => {
        const container = scrollContainerRef.current;
        const children = container.children;
        let childrenWidth = 0;
        for (let i = 0; i < children.length; i++) {
            childrenWidth += children[i].offsetWidth;
        }
        setIsOverflowing(childrenWidth > container.clientWidth);
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    useEffect(() => {
        checkOverflow();
    }, [ids]);

    const scrollContainer = (scrollOffset) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += scrollOffset;
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
            {isOverflowing && <OverTimeButton style={{ backgroundColor: colors.lipstick, borderRadius: 0 }} onClick={() => scrollContainer(-100)}>{"<"}</OverTimeButton>}
            <div ref={scrollContainerRef} style={{ overflowX: 'auto', display: 'flex', gap: 15 }} onScroll={checkOverflow}>
                {ids.map((key, i) => (
                    <OverTimeButton
                        style={selectedIds.includes(key) ? { backgroundColor: colors.lipstick, borderRadius: 0 } : { borderRadius: 0 }}
                        onClick={() => {
                            console.log("select id", key)
                            selectId(key)
                        }}
                    >
                        {key}
                    </OverTimeButton>
                ))}
            </div>
            {isOverflowing && <OverTimeButton style={{ backgroundColor: colors.lipstick, borderRadius: 0 }} onClick={() => scrollContainer(100)}>{">"}</OverTimeButton>}
        </div>
    );
};

export default IdsButtons;

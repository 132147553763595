import { get } from "lodash"

import {
    CONVERSION_FACTOR_CALMNESS,
    INTEREST_HIGH,
    INTEREST_MEDIUM,
    ENGAGEMENT_HIGH,
    ENGAGEMENT_MEDIUM,
    WELLBEING_HIGH,
    WELLBEING_MEDIUM,
    MOOD_HIGH,
    MOOD_MEDIUM,
    STRESS_HIGH,
    STRESS_MEDIUM,
    ENERGY_HIGH,
    ENERGY_MEDIUM
} from "../api/remoteConfig"

import { OVER_TIME_MODULES_BUTTONS } from "../constants"

export const getNormalizedEmotionsMinutes = ({ happiness, calmness, emotional, sad, angry }, totalSeconds, round = true, normalizationType) => {
    let totalSamples = happiness + calmness + sad + angry
    if (!totalSamples) {
        return {
            happiness: 0,
            calmness: 0,
            emotional: 0,
            sad: 0,
            angry: 0
        }
    }
    const factor = totalSeconds / totalSamples;

    let totalSamplesWithEmotional = happiness + calmness + sad + angry + emotional
    const factorincludesEmotional = totalSeconds / totalSamplesWithEmotional;

    return {
        happiness: normalize(happiness, factor, round, normalizationType),
        calmness: normalize(calmness, factor, round, normalizationType),
        emotional: normalize(emotional, factorincludesEmotional, round, normalizationType),
        sad: normalize(sad, factor, round, normalizationType),
        angry: normalize(angry, factor, round, normalizationType)
    }
}

const normalize = (value, factor, round, normalizationType) => {

    let normalized
    normalizationType === 'seconds' ? normalized = (value * factor) : normalized = (value * factor / 60)

    if (round) {
        return Math.round(normalized)
    }
    return normalized
}

export const calcValence = (happiness, emotional, totalMinutes, log) => {
    //  return 0.5 + ((0.5 * happiness) - (0.5 * emotional))
    return Math.round((1 - (emotional / totalMinutes)) * 100);
}

export const calcEnergy = (neutral, totalMinutes) => {
    return Math.round((1 - (CONVERSION_FACTOR_CALMNESS * neutral / totalMinutes)) * 100);
}

export const getTrend = (results, trend) => {
    const {
        calmness,
        emotional,
        happiness,
        sad,
        angry,
        seconds,
        sessions,
        avgSessionTime,
        costSaving,
        wellbeing,
        stress,
        engagement,
        interest,
        valence,
        energy,
        avgScore,
        mood
    } = results;

    const res = {
        calmness: 0,
        emotional: 0,
        sad: 0,
        angry: 0,
        happiness: 0,
        seconds: 0,
        sessions: 0,
        valence: 0,
        energy: 0,
        activeUsers: 0,
        avgSessionTime: 0,
        costSaving: 0,
        calmness_ps: 0,
        happiness_ps: 0,
        sad_ps: 0,
        angry_ps: 0,
        wellbeing: 0,
        stress: 0,
        engagement: 0,
        interest: 0,
        avgScore: 0,
        mood: 0
    }

    if (trend) {
        res.calmness_ps = compareTrend(calmness, trend.calmness_ps)
        //     res.emotional = compareTrend(emotional, trend.emotional)
        res.happiness_ps = compareTrend(happiness, trend.happiness_ps)
        res.sad_ps = compareTrend(sad, trend.sad_ps)
        res.angry_ps = compareTrend(angry, trend.angry_ps)
        res.calmness = compareTrend(calmness, trend.calmness)
        res.happiness = compareTrend(happiness, trend.happiness)
        res.sad = compareTrend(sad, trend.sad)
        res.angry = compareTrend(angry, trend.angry)
        res.seconds = compareTrend(seconds, trend.seconds)
        res.sessions = compareTrend(sessions, trend.sessions)
        res.activeUsers = compareTrend(sessions, trend.activeUsers)
        res.avgSessionTime = compareTrend(avgSessionTime, trend.avgSessionTime)
        res.costSaving = compareTrend(get(costSaving, "value", 0), get(trend, "costSaving.value", 0))
        res.wellbeing_ps = compareTrend(wellbeing, trend.wellbeing)
        res.engagement_ps = compareTrend(engagement, trend.engagement)
        res.interest_ps = compareTrend(interest, trend.interest)
        res.valence_ps = compareTrend(valence, trend.valence)
        res.stress_ps = compareTrend(stress, trend.stress)
        res.stress = compareTrend(stress, trend.stress)
        res.wellbeing = compareTrend(wellbeing, trend.wellbeing)
        res.energy = compareTrend(energy, trend.energy)
        res.engagement = compareTrend(engagement, trend.engagement)
       // console.log("compareTrend", avgScore, trend.avgScore)
        res.avgScore = compareTrend(avgScore, trend.avgScore)
        res.mood = compareTrend(mood, trend.mood)

    }

    return res;
}

const compareTrend = (a, b) => {
    return a - b;
}

export const getEmotionRanger = (emotion) => {
    switch (emotion) {
        case OVER_TIME_MODULES_BUTTONS.ENGAGEMENT:
            return { medium: ENGAGEMENT_HIGH, low: ENGAGEMENT_MEDIUM }
        case OVER_TIME_MODULES_BUTTONS.INTEREST:
            return { medium: INTEREST_HIGH, low: INTEREST_MEDIUM }
        case OVER_TIME_MODULES_BUTTONS.MOOD:
            return { medium: MOOD_HIGH, low: MOOD_MEDIUM }
        case OVER_TIME_MODULES_BUTTONS.WELLBEING:
            return { medium: WELLBEING_HIGH, low: WELLBEING_MEDIUM }
        case OVER_TIME_MODULES_BUTTONS.STRESS:
            return { medium: STRESS_HIGH, low: STRESS_MEDIUM }
        case OVER_TIME_MODULES_BUTTONS.ENERGY:
            return { medium: ENERGY_HIGH, low: ENERGY_MEDIUM }
        default:
            return { medium: 0.6, low: 0.4 }

    }
}

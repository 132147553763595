import { createListenerMiddleware } from '@reduxjs/toolkit'
import {setCurrentHouse} from "./index";
import {analytics} from "../../api/firebase";
import {redirectWithAuthToken} from "../../utils/auth";

export const houseListenerMiddleware = createListenerMiddleware()


houseListenerMiddleware.startListening({
    actionCreator: setCurrentHouse,
    effect: async (action, {dispatch, getState}) => {
        console.log('SET current house: ', action.payload)
        const house = action.payload;
        if(house){
            analytics.setUserProperties({
                houseName: house.name,
                houseId: house.id
            });

            if(house.useLegacyVersion){
                console.log("house is using legacy version - redirect")
                redirectWithAuthToken('legacy')
            }else {
                console.log("house is using new version")
            }
        }

    }
})

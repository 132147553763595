import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import { detect, getDefaultOptions} from "solo-web-engine";
import _ from "lodash";
import {
    calcEnergyTracker,
    calcEngagement,
    calcInterest,
    calcStress,
    calcValenceTracker,
    calcWellbeing, handleDetectionResult
} from "../utils/bioFeedback";

class VideoFaceDetector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            duration: 0,
        }

        this.videoElement = null;
        this.currentTime = 0;
        this.startTime = null; // Add this line
    }

    videoReady = () => {
        console.log("videoReady")
        this.setState({ videoReady: true })
        const {videoStarted} = this.props;

        this.videoElement = document.querySelector("#yo video")
        this.videoElement.addEventListener("loadedmetadata", ()=>{
            console.log("loadedmetadata", {width: this.videoElement.videoWidth, height: this.videoElement.videoHeight})

        })

        if(videoStarted){
            videoStarted()
        }
    }

    videoDuration = (duration) => {
        const { interval } = this.props;
        console.log("duration", duration)
        this.setState({ duration: duration })
        this.player.seekTo(interval / 1000, "friction")
    }

    onSeek = async (seconds) => {
        const { duration } = this.state;
        const { interval, options, onProgress } = this.props;
        if (this.startTime === null) {
            this.startTime = Date.now();
        }
        console.log("onSeek", seconds)
        this.currentTime = seconds;
        onProgress && (onProgress({currentTime: seconds, duration}))
        this.videoElement = document.querySelector("#yo video")
        await this.detectEmotions()
        if (this.currentTime < duration && this.player) {
            this.player.seekTo(this.currentTime + interval / 1000, "friction")
        } else {
            this.props.onFinishDetection && this.props.onFinishDetection()
        }
    }

    ref = player => {
        this.player = player
        console.log("ref", this.player)
        console.log("this.videoElement", this.videoElement)
    }

    detectEmotions = async () => {

        const { options, saveResults } = this.props

        try {
         //   console.time("detectEmotions")
            let {detectionsWithExpressions, faceMeshResult} = await detect(this.videoElement, {
                emotionsModelActive: true,
                handsModelActive: true,
                showFaceLandmarks: false,
                emotionsModelOptions: options,
                returnOriginalFacemesh: true,
               // sizeFilter: false
            })
        //    console.timeEnd("detectEmotions")
        //    console.log("detection", result)
            let currentTime = this.currentTime

            let trackerItem = handleDetectionResult(detectionsWithExpressions, currentTime)

            console.log("result", faceMeshResult)

            saveResults({
                emotions: trackerItem.emotions,
                currentTime,
                result: trackerItem.result,
                faceMeshResult
            })

            //let updatedTracker = _.cloneDeep(tracker)
           // updatedTracker.push(trackerItem)
            //setVideoTracker(updatedTracker)
            if (this.currentTime >= this.state.duration) {
                const endTime = Date.now();
                const elapsedTime = endTime - this.startTime;
                console.log(`Elapsed time: ${elapsedTime} ms`);
                this.startTime = null;
            }


        } catch (e) {
            console.log("error", e)
        }
    }


    render() {
        return (
            <div style={{
                visibility: "hidden",
                position: 'absolute'
            }}>
                <ReactPlayer
                    id={"yo"}
                    ref={this.ref}
                    onReady={this.videoReady}
                    onDuration={this.videoDuration}
                    onSeek={this.onSeek}
                    controls={true} url={this.props.src} />
            </div>
        );
    }
}

VideoFaceDetector.propTypes = {
    interval: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    onFinishDetection: PropTypes.func,
    options: PropTypes.any,
    onProgress: PropTypes.func,

};

export default VideoFaceDetector;
